import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CookieConsent, { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import { Login } from './screens/login/Login';
import { TopNavLayout } from './components/top-nav-layout/TopNavLayout';
import { Footer } from './Footer';
import TermsAndConditions from './screens/terms-and-conditions/TermsAndConditions';
import { AuthLayout } from './components/auth-layout/AuthLayout';
import PrivacyPolicy from './screens/privacy-policy/PrivacyPolicy';
import { initGA } from './utils/ga-utils';
import { ROUTES } from './shared/constants/routes';

const handleAcceptCookie = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }
};

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove('_ga');
  Cookies.remove('_gat');
  Cookies.remove('_gid');
};

function AppRouter() {
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <BrowserRouter>
      <Container fluid className="page-container">
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/linkedin" element={<LinkedInCallback />} />

          <Route element={<TopNavLayout />}>
            <Route path="/termsandconditions" element={<TermsAndConditions />}></Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>

            <Route element={<AuthLayout />}>
              {ROUTES.map((route, index) => {
                return <Route path={route.path} element={route.element} key={index} />;
              })}
            </Route>
          </Route>
        </Routes>
        <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}

export default AppRouter;
