import { useEffect, useState, useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import ReactFlow, {
  ConnectionLineType,
  ConnectionMode,
  Controls,
  ControlButton,
  Edge,
  Node as rfNode,
  NodeOrigin,
  Panel,
  useReactFlow,
  XYPosition,
} from 'reactflow';
import { shallow } from 'zustand/shallow';
import { ColorResult } from 'react-color';

import 'reactflow/dist/style.css';

import useStore, { RFState } from './store';
import RootNode from './components/root-node';
import BranchNode from './components/branch-node';
import MindMapEdge from './components/mind-map-edge';
import ColorMenu from './components/color-menu';
import WalkThrough from './components/walk-through';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  connectionLineStyle: state.connectionLineStyle,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  setNodes: state.setNodes,
  updateConnectionColor: state.updateConnectionColor,
  updateEdgeColor: state.updateEdgeColor,
  thinking: state.thinking,
});

const nodeTypes = {
  root: RootNode,
  branch: BranchNode,
};

const edgeTypes = {
  mindmap: MindMapEdge,
};

const nodeOrigin: NodeOrigin = [1, 1];

function flipChildren(node: rfNode, nodes: rfNode[]) {
  const nodesBeingFlipped: Record<string, number> = { [node.id]: node.width || 0 };

  return nodes.map((n) => {
    if (n.parentNode && n.parentNode in nodesBeingFlipped) {
      nodesBeingFlipped[n.id] = n.width || 0;

      return {
        ...n,
        position: {
          x: -1 * n.position.x + 2 * nodesBeingFlipped[n.parentNode],
          y: n.position.y,
        },
      };
    }
    return n;
  });
}

const MindMap = () => {
  // whenever you use multiple values, you should use shallow to
  // make sure the component only re-renders when one of the values changes
  const [showColorMenu, setShowColorMenu] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const { nodes, edges, connectionLineStyle, onNodesChange, onEdgesChange, updateEdgeColor, setNodes, thinking } =
    useStore(selector, shallow);
  const edgeIdRef = useRef('');
  const [colorMenuPosition, setColorMenuPosition] = useState({} as XYPosition);
  const dragStartX = useRef<number | null>(null);
  const { fitView } = useReactFlow();

  const handleContextMenu = (event: any, edge: Edge) => {
    setShowColorMenu(true);
    edgeIdRef.current = edge.id;
    setColorMenuPosition({ x: event.clientX, y: event.clientY });
    event.preventDefault();
  };

  const handleColorChange = (color: ColorResult) => {
    if (!edgeIdRef.current) {
      return;
    }
    updateEdgeColor(edgeIdRef.current, color.hex);
    edgeIdRef.current = '';
    setShowColorMenu(false);
  };

  const handleMenuClose = () => {
    setShowColorMenu(false);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    // If the click is not within the ColorMenu, hide it
    const colorMenuEl = document.querySelector('.color-menu');
    if (colorMenuEl && !colorMenuEl.contains(event.target as Node)) {
      setShowColorMenu(false);
    }
  };

  useEffect(() => {
    if (!thinking) {
      fitView();
    }
  }, [thinking]);

  useEffect(() => {
    if (showColorMenu) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showColorMenu]);

  const handleDragStart = useCallback((_event: React.MouseEvent, node: rfNode, _nodes: rfNode[]) => {
    dragStartX.current = node.position.x;
  }, []);

  const handleNodeDrag = useCallback(
    (_event: React.MouseEvent, node: rfNode, _nodes: rfNode[]) => {
      if (dragStartX.current && Math.sign(dragStartX.current) != Math.sign(node.position.x)) {
        dragStartX.current = node.position.x;
        setNodes(flipChildren(node, nodes));
      }
    },
    [nodes],
  );

  const handleAutoLayout = useCallback(() => {
    setNodes(nodes.map((node) => ({ ...node, position: { x: node.position.x, y: Infinity } })));
  }, [nodes]);

  const handleStartTourClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setRunTour(true);
  };

  return (
    <>
      <WalkThrough runTour={runTour} setRunTour={setRunTour} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodeOrigin={nodeOrigin}
        connectionLineStyle={connectionLineStyle}
        defaultEdgeOptions={{ style: connectionLineStyle, type: 'mindmap' }}
        connectionLineType={ConnectionLineType.Bezier}
        connectOnClick={false}
        connectionMode={ConnectionMode.Loose}
        onEdgeContextMenu={handleContextMenu}
        onNodeDragStart={handleDragStart}
        onNodeDrag={handleNodeDrag}
        fitView
      >
        <Controls showInteractive={false}>
          <ControlButton title="auto-layout" onClick={handleAutoLayout}>
            <i className="fas fa-sitemap fa-rotate-270"></i>
          </ControlButton>
        </Controls>
        <Panel position="top-right">
          <Button size="lg" variant="success" onClick={handleStartTourClick}>
            Tour ThinkGenius
          </Button>{' '}
        </Panel>
        <Panel position="top-left">ThinkGenius by FourthNexus</Panel>
      </ReactFlow>
      <>
        {showColorMenu && (
          <ColorMenu
            {...colorMenuPosition}
            onColorChange={handleColorChange}
            onMenuClose={handleMenuClose}
            currentColor={connectionLineStyle.stroke}
          />
        )}
      </>
    </>
  );
};

export default MindMap;
