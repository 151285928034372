import JoyRide, { CallBackProps, STATUS, EVENTS, Step } from 'react-joyride';

type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: any;
};

interface ExtendedStep extends Step {
  activateTarget?: string;
  action?: KeyOfType<HTMLElement, () => void>;
}

const steps: ExtendedStep[] = [
  {
    target: '.react-flow__node-root',
    title: 'ThinkGenius Tour',
    content: 'Welcome to the ThinkGenius Tour. Follow along to learn how to use Think Genius!',
    disableBeacon: true,
  },
  {
    target: '.react-flow__node-root',
    content: 'Here is the root of your mindmap. Input your text here to begin.',
  },
  {
    target: '.think-nodes',
    content: 'Click the lightbulb to generate a mind map using AI based on the current mind map.',
  },
  {
    target: '.react-flow__handle-top',
    content: 'You can manually add branches by clicking the handles on each edge of the root.',
    activateTarget: '.react-flow__handle-top',
    action: 'click',
  },
  {
    target: '.node-label',
    content: "After adding a branch, you may edit the label's text or delete the branch by clicking the trash icon.",
    activateTarget: '.node-label',
  },
  {
    target: '.branchHandle',
    content:
      'To add new branches, click the plus icon or the lightbulb to generate more branches based on the selected branch using AI.',
    activateTarget: '.branchHandle',
  },
  {
    target: '.react-flow__panel',
    content:
      'Use the zoom in, zoom out, and fit-to-view buttons to adjust the viewport, and the auto-layout to reformat the mind map.',
  },
  {
    target: '.react-flow__node-root',
    title: 'Think Genius Tour',
    content: 'Thank you for taking the ThinkGenius tour! We hope you found it helpful in using our mind mapping tool.',
  },
];

type WalkThroughProps = {
  runTour: boolean;
  setRunTour: React.Dispatch<React.SetStateAction<boolean>>;
};

const WalkThrough = ({ runTour, setRunTour }: WalkThroughProps) => {
  const handleTourCallback = (data: CallBackProps) => {
    const { status, step, type } = data;
    const extendedStep = step as ExtendedStep;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (extendedStep.activateTarget) {
      const target = document.querySelector(extendedStep.activateTarget) as HTMLElement;
      if (target) {
        switch (type) {
          case EVENTS.STEP_BEFORE:
            target.classList.add('tour-active');
            if (extendedStep.action && extendedStep.action in target) {
              setTimeout(() => target[extendedStep.action!](), 500);
            }
            break;
          case EVENTS.STEP_AFTER:
            target.classList.remove('tour-active');
            break;
        }
      }
    }

    if (finishedStatuses.includes(status)) {
      setRunTour(false);
    }
  };

  return (
    <>
      <JoyRide
        callback={handleTourCallback}
        run={runTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        locale={{ skip: 'Skip Tour' }}
        styles={{
          tooltipContainer: {
            textAlign: 'center',
          },
          buttonNext: {
            backgroundColor: 'green',
          },
          buttonBack: {
            marginRight: 10,
          },
        }}
      />
    </>
  );
};

export default WalkThrough;
