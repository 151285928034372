import React, { FormEvent, useCallback, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import ScrollToBottom from 'react-scroll-to-bottom';
import {
  JsonPortion,
  Message,
  messageLiteral,
  sendWizardMessage,
  TextPortion,
} from '../../services/wizard-chat/wizard-chat';

export type { Message } from '../../services/wizard-chat/wizard-chat';

const renderButtonIfJsonExists = (portion: JsonPortion, onClickOption: (field: string, value: string) => void) => {
  if (portion.json) {
    return (
      <>
        {portion.json.options.map((option) => (
          <Row>
            <Button className="mt-1" onClick={() => onClickOption(portion.json.field, option)}>
              {option}
            </Button>
          </Row>
        ))}
        <Row>
          <small>Click a button to use that value for {portion.json.field}</small>
        </Row>
      </>
    );
  }
};

const GeniusChat = ({
  conversation,
  setConversation,
  setFieldValue,
  context,
}: {
  conversation: Message[];
  setConversation: React.Dispatch<React.SetStateAction<Message[]>>;
  setFieldValue: (field: string, value: string) => void;
  context: unknown;
}) => {
  const [introMessages, setIntroMessages] = useState<Message[]>([
    messageLiteral(
      `Hi there! 👋 I'm PlanGenius. I'm here to help you with your business plan.

Please don't hesitate to ask me if you need help answering any of the above questions!
I'm happy to provide more details or brainstorm answers!`,
      'system',
    ),
  ]);
  const messageRef = React.createRef<HTMLInputElement>();

  const onFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const text = messageRef.current!.value;
      const message: Message = { text, role: 'user', portions: [{ type: 'text', text, thinking: true }] };
      const newConversation = [...conversation, message];

      setConversation(newConversation);

      messageRef.current!.value = '';

      let response;

      try {
        response = await sendWizardMessage({ context, conversation: newConversation, setConversation });
      } catch (e) {
        (message.portions[0] as TextPortion).error = true;
      } finally {
        (message.portions[0] as TextPortion).thinking = false;
      }

      console.log([...newConversation, ...[response]]);
      setConversation([...newConversation, ...(response ? [response] : [])]);
    },
    [conversation, setConversation, messageRef, context],
  );

  return (
    <div className="position-relative h-100 w-100 d-flex flex-column align-items-stretch flex-1 mt-2 border overflow-hidden">
      <div className="flex-1, overflow-hidden">
        <ScrollToBottom className="h-100">
          <Container className="message-container px-0">
            {[...introMessages, ...conversation].map((message, index) => {
              return (
                <Row
                  className="pt-1 border-top border-dark d-flex gap-1 align-items-baseline"
                  style={{ background: message.role === 'user' ? 'white' : '#e1e1e1' }}
                  key={`message-${index}`}
                >
                  <div className="position-relative w-auto d-flex flex-column align-items-end pr-0">
                    <i className={`fas ${message.role === 'user' ? 'fa-user' : 'fa-robot'}`}></i>
                  </div>
                  <div className="position-relative d-flex flex-column message-text">
                    {message.portions &&
                      message.portions.map((portion, index) => {
                        switch (portion.type) {
                          case 'text':
                            return (
                              <React.Fragment key={`portion-${index}`}>
                                <p>{portion.text}</p>
                                <div className="mb-2 position-absolute end-0">
                                  {portion.thinking && (
                                    <Spinner animation="border" size="sm" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                  )}
                                  {portion.error && (
                                    <i
                                      className="fas fa-exclamation-triangle text-warning"
                                      title="Failed to send message. Click to retry"
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          case 'json':
                            return <Container>{renderButtonIfJsonExists(portion, setFieldValue)}</Container>;
                        }
                      })}
                  </div>
                </Row>
              );
            })}
            <div className="flex-shrink-0 blank-space" />
          </Container>
        </ScrollToBottom>
      </div>

      <div className="position-absolute bottom-0 w-100">
        <form className="mx-auto d-flex gap-3 input-container" onSubmit={onFormSubmit}>
          <div className="d-flex flex-column w-100 position-relative justify-content-around">
            <input type="text" ref={messageRef} className="w-100"></input>
            <Button type="submit" className="position-absolute p-2 border-0 text-secondary end-0 me-2 bg-transparent">
              <i className="fas fa-comment" />
            </Button>
          </div>
        </form>
        <Row className="pb-3" />
      </div>
    </div>
  );
};

export default GeniusChat;
