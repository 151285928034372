import { Questionnaire } from '../../types/questionnaire';

export const QUESTIONNAIRE: Questionnaire = {
  'Company Description': {
    businessName: {
      question: 'Business Name',
      required: true,
    },
    productsAndServices: {
      question: 'What products and services do you plan to offer?',
      required: true,
    },
    legalStructure: {
      question: 'What is the legal structure of the company?',
      required: true,
    },
    ownershipStructure: {
      question: 'What is the ownership structure of the company?',
      required: true,
    },
    companyHistory: {
      question: 'What is the history of the company?',
      required: true,
    },
    location: {
      question: 'What is the location of the company?',
      required: true,
    },
    missionAndVision: {
      question: "What is the company's mission and vision statement?",
      required: true,
    },
    targetMarket: {
      question: 'What is your target market?',
      required: true,
    },
  },
  'Financial Forecast': {
    projectedSales: {
      question: 'What are the projected sales and revenue for the business over the next 1-5 years?',
      required: true,
    },
    projectedCosts: {
      question: 'What are the expected costs associated with the business, including variable and fixed costs?',
      required: true,
    },
    capital: {
      question: 'How much capital will be required to start and sustain the business?',
      required: true,
    },
    anticipatedCashFlow: {
      question: 'What are the anticipated cash flow needs and how will they be met?',
      required: true,
    },
    anticipatedReturnOnInvestment: {
      question: 'What is the anticipated return on investment for the business?',
      required: true,
    },
    marketTrendsAndIndustryChange: {
      question: 'How will market trends and changes in the industry impact the financial performance of the business?',
      required: true,
    },
    projectedFinancialStatements: {
      question:
        'What are the projected financial statements for the business, including balance sheet, income statement, and cash flow statement?',
      required: true,
    },
    keyFinancialMetrics: {
      question: 'What are the key financial metrics that will be used to measure the performance of the business?',
      required: true,
    },
  },
  /*'SWOT Strategic Analysis': {
    strengths: {
      question: 'What are the strengths of the business/project?',
    },
    weaknesses: {
      question: 'What are the weaknesses of the business/project?',
    },
    opportunities: {
      question: 'What are the opportunities available to the business/project?',
    },
    threats: {
      question: 'What are the threats to the business/project?',
    },
    leveragingStrengths: {
      question: 'How can the business/project leverage its strengths to take advantage of opportunities?',
    },
    overcomingWeaknesses: {
      question: 'How can the business/project address its weaknesses to overcome the threats?',
    },
  },*/

  /*'Customer Analysis': {
    productTargetCustomer: {
      question: 'Who is the target customer for the product or service?',
    },
    demographics: {
      question:
        'What are the demographics of the target customer, such as age, gender, income level, education level, and location?',
    },
    psychographics: {
      question:
        'What are the psychographics of the target customer, such as lifestyle, values, interests, and personality traits?',
    },
    wantsAndNeeds: {
      question:
        'What are the needs and wants of the target customer, and how does the product or service meet those needs and wants?',
    },
    currentlyAddressingNeeds: {
      question: 'How does the target customer currently address the need that the product or service fulfills?',
    },
    marketSize: {
      question: 'What is the size of the target market, and how fast is it growing?',
    },
    keyCompetitors: {
      question: 'Who are the key competitors in the market, and what are their strengths and weaknesses?',
    },
    customerTrendsAndDrivers: {
      question: 'Customer Trends and Drivers',
    },
    brandPerception: {
      question: "What is the target customer's perception of the brand, and how can it be improved?",
    },
    pricing: {
      question:
        'How will the product or service be priced to appeal to the target customer while still maintaining profitability?',
    },
  },*/

  /*'Industry & Competitive Analysis': {
    sizeAndGrowthRate: {
      question: 'What is the size and growth rate of the industry?',
    },
    majorPlayers: {
      question: 'Who are the major players in the industry?',
    },
    industryTrendsAndDrivers: {
      question: 'What are the key trends and drivers in the industry?',
    },
    barriersToEntry: {
      question: 'What are the barriers to entry in the industry?',
    },
    industryTargetCustomer: {
      question: 'Who are the target customers for the industry?',
    },
    competitiveLandscape: {
      question: 'What is the competitive landscape of the industry?',
    },
    industryStrengthsAndWeaknesses: {
      question: 'What are the strengths and weaknesses of the major players in the industry?',
    },
    threatsAndOpportunities: {
      question: 'What are the threats and opportunities in the industry?',
    },
    regulatoryAndLegalIssues: {
      question: 'What are the regulatory and legal issues that affect the industry?',
    },
    futureOfTheIndustry: {
      question: 'How is the industry likely to change in the future?',
    },
  },*/

  /*'TAM Calculation or Analysis': {
    marketSize: {
      question: 'What is the size of the target market, and how fast is it growing?',
    },
    tamTargetMarket: {
      question: 'What is the target market for the product or service?',
    },
    customerPurchasingPower: {
      question: "What is the target customer's purchasing power and behavior?",
    },
    customerWillingness: {
      question: "What is the target customer's willingness to pay for the product or service?",
    },
    marketTrendsAndDrivers: {
      question:
        "What are the market trends and drivers that affect the target market's behavior and purchasing decisions?",
    },
    marketGrowth: {
      question: "What is the target market's growth rate and potential for future expansion?",
    },
    marketSubSegments: {
      question: 'Are there any sub-segments within the target market that can be further analyzed?',
    },
    geographicInclusionsOrExclusions: {
      question: 'Are there any geographic regions that need to be included or excluded from the analysis?',
    },
    targetMarketSize: {
      question: 'How does the target market size compare to the overall industry size?',
    },
    externalFactors: {
      question:
        'Are there any external factors, such as economic conditions or regulatory changes, that could affect the TAM calculation or analysis?',
    },
  },*/
};
