import { useMemo } from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { logout } from '../../services/auth/AuthService';
import { ROUTES } from '../../shared/constants/routes';
import { useBoundStore } from '../../store';

export function TopNav() {
  const { user, onLogout } = useBoundStore();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    onLogout();
  };

  const navs = useMemo(() => {
    return ROUTES.filter((route) => !route.noNav).map((route, index) => {
      return (
        <Nav.Item key={index}>
          <Nav.Link href={route.path}>{route.label}</Nav.Link>
        </Nav.Item>
      );
    });
  }, [ROUTES]);

  return (
    <Navbar sticky="top" bg="light">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src="/logo.png" className="d-inline-block align-top" height="50" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Collapse className="align-self-end tabs">
          <Nav variant="tabs" defaultActiveKey="/" activeKey={location.pathname}>
            {navs}
          </Nav>
        </Navbar.Collapse>
        {user && (
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Button variant="dark" onClick={handleLogout}>
                Logout
              </Button>
            </Navbar.Text>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}
