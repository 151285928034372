import { ReactFlowProvider } from 'reactflow';

import { shallow } from 'zustand/shallow';

import 'reactflow/dist/style.css';

import './index.css';
import useStore, { RFState } from './store';
import ThinkingOverlay from './components/thinking-overlay';
import MindMap from './MindMap';

const selector = (state: RFState) => ({
  thinking: state.thinking,
});

const ThinkGenius = () => {
  const { thinking } = useStore(selector, shallow);

  return (
    <>
      <ThinkingOverlay thinking={thinking} />
      <ReactFlowProvider>
        <MindMap />
      </ReactFlowProvider>
    </>
  );
};

export default ThinkGenius;
