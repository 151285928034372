import { Questionnaire } from '../../types/questionnaire';

export const QUESTIONNAIRE: Questionnaire = {
  Preliminaries: {
    productsAndServices: {
      question: 'What products and services do you plan to offer?',
      required: true,
    },
    location: {
      question: 'What is the location of the company?',
      required: true,
    },
  },
  'Company Profile': {
    businessName: {
      question: 'Business Name',
      required: true,
    },
    legalStructure: {
      question: 'What is the legal structure of the company?',
    },
    ownershipStructure: {
      question: 'What is the ownership structure of the company?',
    },
  },
  'Company Background': {
    missionAndVision: {
      question: "What is the company's mission and vision statement?",
      required: true,
    },
    companyHistory: {
      question: 'What is the history of the company?',
    },
    targetMarket: {
      question: 'What is your target market?',
    },
  },
  'Financial Projections': {
    projectedSales: {
      question: 'What are the projected sales and revenue for the business over the next 1-5 years?',
      required: true,
    },
    projectedCosts: {
      question: 'What are the expected costs associated with the business, including variable and fixed costs?',
      required: true,
    },
    anticipatedReturnOnInvestment: {
      question: 'What is the anticipated return on investment for the business?',
    },
  },
  'Financial Needs': {
    capital: {
      question: 'How much capital will be required to start and sustain the business?',
      required: true,
    },
    anticipatedCashFlow: {
      question: 'What are the anticipated cash flow needs and how will they be met?',
    },
    marketTrendsAndIndustryChange: {
      question: 'How will market trends and changes in the industry impact the financial performance of the business?',
    },
  },
  'Financial Statements': {
    keyFinancialMetrics: {
      question: 'What are the key financial metrics that will be used to measure the performance of the business?',
      required: true,
    },
    projectedFinancialStatements: {
      question:
        'What are the projected financial statements for the business, including balance sheet, income statement, and cash flow statement?',
    },
  },
};
