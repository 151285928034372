import React from 'react';

import './App.css';

import CompanyForm from './screens/company-form/CompanyForm';

function App() {
  return <CompanyForm />;
}

export default App;
