import { Node } from 'reactflow';

export interface MindmapNode {
  id: string;
  label: string;
  parent?: string;
}

export const completeGraph = async (contextNodeId: string, nodes: Node[]) => {
  console.log('CompleteGraphNodes:', nodes);
  const mindmapNodes = nodes.map((node) => ({ id: node.id, label: node.data.label, parent: node.parentNode }));
  const mindMap = await generateMindmap(contextNodeId, mindmapNodes);

  const newNodes = mindMap.map((node) => ({
    id: node.id.toString(),
    data: {
      label: node.label,
    },
    type: node.parent ? 'branch' : 'root',
    parentNode: node.parent?.toString(),
    position: { x: Infinity, y: Infinity },
  }));

  const newEdges = mindMap
    .filter((n) => n.parent)
    .map((node) => ({
      id: `e${node.parent}-${node.id}`,
      source: node.parent!.toString(),
      target: node.id.toString(),
    }));

  return {
    nodes: newNodes,
    edges: newEdges,
  };
};

const generateMindmap = async (contextNodeId: string, nodes: MindmapNode[]): Promise<MindmapNode[]> => {
  return fetch(process.env.REACT_APP_API_BASE_URL + '/generateMindmap', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: 'Bearer ' + getToken(),
    },
    body: JSON.stringify({ contextNodeId, nodes }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }
      //setIsSubmitted(true);
      return res.json();
    })
    .catch((error) => {
      console.error('Error:', error.message);
      //setErrorMessage({
      //  error: error.message,
      //  message: 'Failed to submit the form. Please try again later. Please contact us if the problem persists.',
      //});
    });
};
