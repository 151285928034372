import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

interface ErrorMessage {
  message: string;
}

type ToastErrorContainerProps = {
  errorMessage: ErrorMessage | null;
  onClose: () => void;
};

export function ToastErrorContainer(props: ToastErrorContainerProps) {
  const { errorMessage, onClose } = props;

  return (
    <ToastContainer position="top-center" className="position-fixed">
      {errorMessage && (
        <Toast onClose={onClose} bg="danger" delay={7000} autohide={false}>
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{errorMessage.message}</Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
}
