import { Outlet } from 'react-router-dom';

import { TopNav } from '../top-nav/TopNav';

export const TopNavLayout = () => {
  return (
    <>
      <TopNav />
      <Outlet />
    </>
  );
};
