import jwt_decode from 'jwt-decode';

export function login(jwtToken) {
  const decoded = jwt_decode(jwtToken);
  setWithExpiry('token', jwtToken, decoded.exp * 1000);

  return getCurrentUser();
}

export function logout() {
  localStorage.removeItem('token');
}

export function getToken() {
  return getWithExpiry('token');
}

export function getCurrentUser() {
  const token = getToken();
  if (!token) {
    return undefined;
  }

  const decoded = jwt_decode(token);

  return {
    email: decoded.email,
    givenName: decoded.given_name,
    familyName: decoded.family_name,
    name: decoded.name,
    picture: decoded.picture,
  };
}

export function setScopedToken(provider, token) {
  return set(`scopedToken-${provider}`, token);
}

export function getScopedToken(provider) {
  return get(`scopedToken-${provider}`);
}

function setWithExpiry(key, value, expiry) {
  const item = {
    value,
    expiry,
  };

  set(key, item);
}

function set(key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

function get(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  try {
    return JSON.parse(itemStr);
  } catch {
    return null;
  }
}

function getWithExpiry(key) {
  const item = get(key);
  if (!item) {
    return null;
  }

  try {
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch {
    return null;
  }
}
