import React, { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import { login } from '../../services/auth/AuthService';
import { ToastErrorContainer } from '../../components/toast-errors/ToastErrorContainer';
import { useBoundStore } from '../../store';
import { ErrorWithMessage } from '../../types/error';

import './Login.css';
import { getPageInfo } from '../../services/PageInfo';

export function Login() {
  const { user, onLogin } = useBoundStore();
  const [errorMessage, setErrorMessage] = useState<ErrorWithMessage | null>(null);
  const handleCloseErrorToast = () => setErrorMessage(null);
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID!,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: 'openid profile email',
    onSuccess: async (code) => {
      return fetch(
        process.env.REACT_APP_API_BASE_URL + '/getOAuthToken',
        //'http://localhost:8080',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code, type: 'linkedin' }),
        },
      ).then(async (res) => {
        const credentialResponse = await res.json();
        console.log(credentialResponse);

        if (credentialResponse.id_token) {
          const currentUser = login(credentialResponse.id_token);
          onLogin(currentUser);
        }
      });
    },
    onError: (error) => {
      console.log(error);
      setErrorMessage({
        error: error,
        message: 'Failed to login with LinkedIn. Please try again later. Please contact us if the problem persists.',
      });
    },
  });

  const pageInfo = useMemo(() => {
    return getPageInfo(window.location.host);
  }, []);

  return user ? (
    <Navigate to="/" />
  ) : (
    <section className="h-100 gradient-form" style={{ backgroundColor: '#eee' }}>
      <ToastErrorContainer errorMessage={errorMessage} onClose={handleCloseErrorToast} />
      <Container className="py-5 h-100">
        <Row className="d-flex justify-content-center align-items-center">
          <Col xl={10}>
            <Card className="rounded-3 text-black">
              <Row className="g-0">
                <Col lg={6}>
                  <Card.Body className="p-md-5 mx-md-4 d-flex flex-column h-100">
                    <div className="text-center">
                      <img src="logo.png" style={{ width: '20rem' }} alt="logo" />
                      <h4 className="mt-3 mb-4 pb-1">{pageInfo.title} by FourthNexus</h4>
                    </div>

                    <Container>
                      <p>Please Sign In/Sign Up to continue</p>
                      <Stack gap={2}>
                        <Row>
                          <GoogleLogin
                            width="318"
                            onSuccess={(credentialResponse) => {
                              if (credentialResponse.credential) {
                                const currentUser = login(credentialResponse.credential);
                                onLogin(currentUser);
                              }
                            }}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                          />
                        </Row>
                        <Row>
                          <img
                            onClick={linkedInLogin}
                            src="/signin_with_linkedin.png"
                            alt="Sign in with Linked In"
                            style={{ maxWidth: '342px', cursor: 'pointer' }}
                          />
                        </Row>
                      </Stack>
                    </Container>
                    <Row className="mt-auto">
                      <small style={{ fontSize: '0.75rem' }}>
                        NOTE: FourthNexus never sells your data. The email address associated with your account will not
                        be used for unsolicited communications and is only be used to email PlanGenius results.
                      </small>
                    </Row>
                  </Card.Body>
                </Col>
                <Col lg={6} className="d-flex align-items-center gradient-custom-2">
                  <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                    <h4 className="mb-4">Transforming businesses, exceeding expectations</h4>
                    <p className="small mb-0">
                      By staying at the forefront of technological innovation and harnessing the latest breakthroughs in
                      artificial intelligence, the Genius Tools suite exemplifies FourthNexus's unwavering dedication to
                      pushing the boundaries of technology. Our relentless pursuit of the most cutting-edge advancements
                      empowers us to deliver unparalleled solutions for our customers.
                    </p>
                    <h4 className="mt-4">Genius Tools Suite</h4>
                    <ul>
                      <li>
                        <strong>PlanGenius</strong> - <small>Automated business plan generation</small>
                      </li>
                      <li>
                        <strong>SurveyGenius</strong> - <small>Automated survey creation with Google Forms</small>
                      </li>
                      <li>
                        <strong>ThinkGenius</strong> - <small>AI enhanced mind mapping</small>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
