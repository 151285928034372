import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to the Genius Tools by FourthNexus. If you continue to browse and use this website, you are agreeing to
        comply with and be bound by the following terms and conditions of use, which together with our privacy policy
        govern our relationship with you in relation to this website.
      </p>
      <h2>Content</h2>
      <p>
        The content of the pages of this website is for your general information and use only. It is subject to change
        without notice.
      </p>
      <h2>Disclaimer</h2>
      <p>
        The information contained in this website is for general information purposes only. While we endeavor to keep
        the information up to date and correct, we make no representations or warranties of any kind, express or
        implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website
        or the information, products, services, or related graphics contained on the website for any purpose. Any
        reliance you place on such information is therefore strictly at your own risk.
      </p>
      <h2>Changes to the Terms and Conditions</h2>
      <p>
        We reserve the right to change these terms and conditions from time to time as we see fit and your continued use
        of the site will signify your acceptance of any adjustment to these terms.
      </p>
      <h2>Contact Us</h2>
      <p>If you have any questions about these terms and conditions, please contact us.</p>
    </div>
  );
};

export default TermsAndConditions;
