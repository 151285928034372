import { ReactNode } from 'react';
import App from '../../App';
import PlanGeniusWizard from '../../screens/plan-genius-wizard/PlanGeniusWizard';
import SurveyPage from '../../screens/survey/SurveyPage';
import ThankYouPage from '../../screens/thank-you/ThankYouPage';
import ThinkGenius from '../../screens/think-genius/ThinkGenius';

type RouteSpec = {
  path: string;
  element: ReactNode;
  label?: string;
  noNav?: boolean;
};

const sharedUrls = ['localhost', 'genius.fourthnexus.dev', 'genius-dev.fourthnexus.dev'];
const planGeniusUrls = [...sharedUrls, 'plan-genius.fourthnexus.dev', 'plan-genius-dev.fourthnexus.dev'];
const surveyGeniusUrls = ['survey-genius.fourthnexus.dev', 'survey-genius-dev.fourthnexus.dev'];
const thinkGeniusUrls = ['think-genius.fourthnexus.dev', 'think-genius-dev.fourthnexus.dev'];

export enum Route {
  Wizard,
  Plan,
  Thanks,
  Survey,
  Think,
}

export const RouteMap: Record<Route, RouteSpec> = {
  [Route.Wizard]: {
    path: pathForHostname(window.location.hostname, planGeniusUrls, '/wizard'),
    label: 'PlanGenius (wizard)',
    element: <PlanGeniusWizard />,
  },
  [Route.Plan]: {
    path: '/plan',
    label: 'PlanGenius (classic)',
    element: <App />,
  },
  [Route.Thanks]: {
    path: '/thanks',
    element: <ThankYouPage />,
    noNav: true,
  },
  [Route.Survey]: {
    path: pathForHostname(window.location.hostname, surveyGeniusUrls, '/survey'),
    label: 'SurveyGenius',
    element: <SurveyPage />,
  },
  [Route.Think]: {
    path: pathForHostname(window.location.hostname, thinkGeniusUrls, '/think'),
    label: 'ThinkGenius',
    element: <ThinkGenius />,
  },
};

function pathForHostname(hostname: string, urls: string[], defaultPath: string): string {
  if (urls.includes(hostname)) {
    return '/';
  }
  return defaultPath;
}

export const ROUTES: RouteSpec[] = Object.values(RouteMap);
