import { ColorResult, CirclePicker } from 'react-color';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

type ColorMenuProps = {
  x: number;
  y: number;
  currentColor: string;
  onMenuClose: () => void;
  onColorChange: (color: ColorResult) => void;
};

function ColorMenu({ x, y, currentColor, onMenuClose, onColorChange }: ColorMenuProps) {
  return (
    <div className="color-menu" style={{ top: `${y}px`, left: `${x}px` }}>
      <div className="close-color-menu">
        {' '}
        <FontAwesomeIcon icon={faXmark} size="sm" className="close-color-icon" onClick={onMenuClose} />
      </div>
      <div>
        <CirclePicker
          width="11.5rem"
          circleSize={16}
          circleSpacing={14}
          color={currentColor}
          onChangeComplete={onColorChange}
        ></CirclePicker>
      </div>
    </div>
  );
}

export default ColorMenu;
