import { processText } from './parser';
import { getToken } from '../auth/AuthService';

export type TextPortion = {
  type: 'text';
  text: string;
  thinking?: boolean;
  error?: boolean;
};

export type JsonPortion = {
  type: 'json';
  json: {
    field: string;
    options: string[];
  };
};

type MessagePortion = TextPortion | JsonPortion;

//FIXME: split request and response types
export type Message = {
  text: string;
  role: string;
  portions: MessagePortion[];
};

export const messageLiteral = (text: string, role: string): Message => {
  return {
    text,
    role,
    portions: [
      {
        type: 'text',
        text,
      },
    ],
  };
};

type WizardProps = {
  context: unknown;
  conversation: Message[];
  setConversation: React.Dispatch<React.SetStateAction<Message[]>>;
};

export const sendWizardMessage = async ({ context, conversation, setConversation }: WizardProps): Promise<Message> => {
  return fetch(
    process.env.REACT_APP_WIZARD_CHAT_URL!,
    //'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
      body: JSON.stringify({ context, conversation }),
    },
  ).then(async (res) => {
    /*if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
        }*/
    const reader = res.body!.getReader();

    const response: Message = {
      text: '',
      role: 'assistant',
      portions: [{ type: 'text', text: '' }],
    };

    let jsonBuffer = '';
    let currentPortion: TextPortion = response.portions![0] as TextPortion;

    // read() returns a promise that resolves when a value has been received
    await reader.read().then(function pump({ done, value }): Promise<void> | void {
      const streamValue = new TextDecoder().decode(value);

      response.text += streamValue;

      processText(
        streamValue,
        (c) => {
          currentPortion.text += c;
        },
        (c) => {
          jsonBuffer += c;
          currentPortion.thinking = true;
          try {
            const json = JSON.parse(jsonBuffer);
            response.portions!.push({ type: 'json', json });
            jsonBuffer = '';
          } catch {}
        },
        () => {
          currentPortion.thinking = false;
          currentPortion = { type: 'text', text: '' };
          response.portions!.push(currentPortion);
        },
      );

      // FIXME: These two lines should be handled via a caller supplied callback
      (conversation[conversation.length - 1].portions[0] as TextPortion).thinking = false;
      setConversation([...conversation, response]);

      if (done) {
        return;
      }

      // Read some more, and call this function again
      return reader.read().then(pump);
    });

    return response;
  });
};
