import React from 'react';
import { Accordion } from 'react-bootstrap';
import { QuestionnaireSection } from '../../types/questionnaire';
import { FieldGroup } from '../field-group/FieldGroup';

export const FormSection = ({
  label,
  questions,
  eventKey,
}: {
  label: string;
  questions: QuestionnaireSection;
  eventKey: string;
}) => (
  <Accordion.Item eventKey={eventKey} as="fieldset">
    <Accordion.Header as="legend">{label}</Accordion.Header>
    <Accordion.Body>
      {Object.entries(questions).map(([id, question]) => (
        <FieldGroup label={question.question} name={id} required={question.required} key={id} />
      ))}
    </Accordion.Body>
  </Accordion.Item>
);
