enum State {
  TEXT = 'text',
  T1 = 't1',
  T2 = 't2',
  CODE = 'code',
  C1 = 'c1',
  C2 = 'c2',
}

interface StateMachine<S extends string> {
  [key: string]: {
    entry?: (ctx: any) => void;
    on: {
      [key: string]: {
        target?: S;
        action?: (c: string, ctx: any) => void;
      };
    };
  };
}

interface ExecutionContext {
  onText?: (c: string) => void;
  onCode?: (c: string) => void;
  onTextEntry?: () => void;
}

const onText = (c: string, ctx: ExecutionContext) => ctx.onText && ctx.onText(c);
const onCode = (c: string, ctx: ExecutionContext) => ctx.onCode && ctx.onCode(c);
const onTextEntry = (ctx: ExecutionContext) => ctx.onTextEntry && ctx.onTextEntry();

const stateMachine: StateMachine<State> = {
  text: { on: { '`': { target: State.T1 }, default: { action: onText } }, entry: onTextEntry },
  t1: { on: { '`': { target: State.T2 }, default: { target: State.TEXT, action: onText } } },
  t2: { on: { '`': { target: State.CODE }, default: { target: State.TEXT, action: onText } } },

  code: { on: { '`': { target: State.C1 }, default: { action: onCode } } },
  c1: { on: { '`': { target: State.C2 }, default: { target: State.CODE, action: onCode } } },
  c2: { on: { '`': { target: State.TEXT }, default: { target: State.CODE, action: onCode } } },
};

let state: State = State.TEXT;

export function processText(
  text: string,
  onText: (c: string) => void,
  onCode: (c: string) => void,
  onTextEntry: () => void,
) {
  const ctx = {
    onText,
    onCode,
    onTextEntry,
  };

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    let event = char;

    if (!stateMachine[state].on[event]) {
      event = 'default';
    }

    if (stateMachine[state].on[event]) {
      const transition = stateMachine[state].on[event];

      if (transition.action) {
        transition.action(char, ctx);
      }

      const newState = transition.target || state;
      if (state != newState && stateMachine[newState].entry) {
        stateMachine[newState]!.entry!(ctx);
      }
      state = newState;
    }
  }
}
