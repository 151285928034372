import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Route, RouteMap } from '../../shared/constants/routes';

const ThankYouPage = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Thank You for Your Submission!</h1>
          <p>
            Our automated business plan generator is working hard to generate a customized plan based on the information
            you provided.
          </p>
          <p>
            You should receive your business plan as a PDF attachment via email shortly. If you have any questions or
            concerns, please don't hesitate to contact us.
          </p>
          <p>
            Please note that the business plans generated by this service are for informational purposes only. The
            service is provided without warranty and no suggestion is made on the fitness of the generated plans for any
            purpose.
          </p>
          <Button variant="primary" href={RouteMap[Route.Plan].path}>
            Back to Home
          </Button>{' '}
          <Button variant="secondary" href="mailto:info@fourthnexus.com">
            Contact Us
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYouPage;
