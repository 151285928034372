import React, { FormEvent, useState } from 'react';
import { Form, Button, Container, Row, Accordion, ListGroup, Spinner } from 'react-bootstrap';
import { CodeResponse, TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { ToastErrorContainer } from '../../components/toast-errors/ToastErrorContainer';
import { SurveyCard } from '../../components/survey-card/SurveyCard';
import { getScopedToken, getToken, setScopedToken } from '../../services/auth/AuthService';
import { DeferredPromise, useDeferredPromise } from '../../hooks/useDeferredPromise';
import { Survey, SurveySpec } from '../../types/survey';
import { ErrorWithMessage } from '../../types/error';

const requiredScopes = ['https://www.googleapis.com/auth/forms.body'];

type AccessRequestFn = () => Promise<TokenResponse>;

const obtainAccess = async (requestAccess: AccessRequestFn): Promise<TokenResponse> => {
  let scopedToken = getScopedToken('google') || { scope: '' };
  const scopes = scopedToken.scope.split(' ');

  const hasAccess = requiredScopes.every((scope) => scopes.includes(scope));

  if (!hasAccess) {
    scopedToken = await requestAccess();
    setScopedToken('google', scopedToken);
  }

  return scopedToken;
};

type RequestSurveyProps = {
  token: TokenResponse;
  surveySpec: SurveySpec;
  setIsSubmitted: (isSubmitted: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

const requestSurvey = async ({ token, surveySpec, setIsSubmitted, setIsSubmitting }: RequestSurveyProps) => {
  console.log('Submitted Values:', JSON.stringify(surveySpec));

  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/generateSurvey',
    //'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
      body: JSON.stringify({ ...surveySpec, token }),
    },
  ).then(async (res) => {
    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }
    setIsSubmitted(true);
    const { formId, surveyUrl } = await res.json();
    const editUrl = `https://docs.google.com/forms/d/${formId}/edit`;
    setIsSubmitting(false);
    const newSurvey = { editUrl, responseUrl: surveyUrl, name: surveySpec.surveyName };
    return newSurvey;
  });
};

const generateSurvey = async ({
  requestAccess,
  ...rest
}: { requestAccess: AccessRequestFn } & Omit<RequestSurveyProps, 'token'>) => {
  const token = await obtainAccess(requestAccess);

  return requestSurvey({ ...rest, token });
};

const onGoogleLogin = async (resp: CodeResponse, promise: DeferredPromise<TokenResponse>) => {
  try {
    const token = await getGoogleAuth(resp);

    promise.resolve(token);
  } catch (error) {
    promise.reject(error);
  }
};

const getGoogleAuth = async (resp: CodeResponse) => {
  return fetch(process.env.REACT_APP_API_BASE_URL + '/getOAuthToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...resp, type: 'google' }),
  }).then(async (res) => {
    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return res.json();
  });
};

const SurveyPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [_isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorWithMessage | null>(null);

  const [surveyList, setSurveyList] = useState<Survey[]>([]);
  const [surveyName, setSurveyName] = useState('');
  const [question, setQuestion] = useState('');

  const { defer, deferRef } = useDeferredPromise<TokenResponse>();

  const googleLogin = useGoogleLogin({
    onSuccess: (resp) => onGoogleLogin(resp, deferRef.current!),
    onError: (error) => deferRef.current!.reject(error),
    onNonOAuthError: (error) => deferRef.current!.reject(error),
    flow: 'auth-code',
    scope: requiredScopes.join(' '),
  });

  const requestAccess = async () => {
    googleLogin();
    return defer().promise as Promise<TokenResponse>;
  };

  function addSurveyToList(newSurvey: Survey) {
    setSurveyList([...surveyList, newSurvey]);
  }

  const handleSurveySubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const surveySpec = {
      surveyName,
      question,
    };
    try {
      const newSurvey = await generateSurvey({
        surveySpec,
        setIsSubmitted,
        setIsSubmitting,
        requestAccess,
      });
      addSurveyToList(newSurvey);
    } catch (error: any) {
      console.error('Failed to submit survey', error);
      setErrorMessage({
        error: error.message,
        message: 'Failed to submit the survey info. Please try again later. Please contact us if the problem persists.',
      });
      setIsSubmitting(false);
    }
  };

  const handleCloseErrorToast = () => setErrorMessage(null);

  return (
    <Container className="mt-4">
      <Row>
        <Accordion defaultActiveKey={['instructions']} alwaysOpen>
          <Accordion.Item eventKey="instructions">
            <Accordion.Header>How to Use SurveyGenius</Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">
                  Fill out the Survey Name. This name will be displayed to users taking the survey and will be the
                  survey name in your Google Drive
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  Fill out the Question field with an overarching question that the survey will seek to answer. You may
                  enter multiple questions and provide additional context.
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  GPT will be leveraged to generate a Google Form with multiple questions pertaining to your question
                  asked. The Google form will be created in your Google Drive, and links will also be provided below
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="mt-3">
        <Form onSubmit={handleSurveySubmit}>
          <ToastErrorContainer errorMessage={errorMessage} onClose={handleCloseErrorToast} />
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Survey Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Example Survey Name"
              onChange={(e) => setSurveyName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Question(s) <small>(Add additional context as appropriate)</small>:
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="What question would you like answered by your survey?"
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isSubmitting || !surveyName || !question}>
            {isSubmitting ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              <>Submit</>
            )}
          </Button>
        </Form>
      </Row>
      {surveyList.length > 0 && (
        <Row className="mt-3 d-flex flex-wrap">
          <h2>Survey(s) Generated</h2>
          <div className="flex-column">
            {surveyList.map((survey, index) => (
              <SurveyCard key={index} survey={survey} index={index} />
            ))}
          </div>
        </Row>
      )}
    </Container>
  );
};

export default SurveyPage;
