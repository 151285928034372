// Build the yup schema from the questionnaire
import * as Yup from 'yup';
import { QUESTIONNAIRE } from './questionnaire';

const questionnaireSchemaMapping: Record<string, Yup.Schema> = {};

Object.keys(QUESTIONNAIRE).forEach((key) => {
  const section = QUESTIONNAIRE[key];
  Object.keys(section).forEach((sectionKey) => {
    const question = section[sectionKey];
    questionnaireSchemaMapping[sectionKey] = question.required
      ? Yup.string().required('This field is required')
      : Yup.string();
  });
});

export const companyFormSchema = Yup.object().shape(questionnaireSchemaMapping);
