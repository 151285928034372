import { Field } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AcceptTerms = () => (
  <FormGroup>
    <Field type="checkbox" className="form-check-input" name="acceptTerms" />{' '}
    <FormLabel>
      Accept{' '}
      <Link to="/termsandconditions" target="_blank">
        Terms & Conditions
      </Link>{' '}
      and{' '}
      <Link to="/privacypolicy" target="_blank">
        Privacy Policy
      </Link>
    </FormLabel>
  </FormGroup>
);
