import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useId } from 'react';
import { FormText, Form, FormControl } from 'react-bootstrap';

type FieldGroupProps = {
  label: string;
  name: string;
  required?: boolean;
};

export const FieldGroup = ({ label, name, required = false }: FieldGroupProps) => {
  const id = useId();
  const errorId = useId();
  return (
    <Form.Group className="mb-2">
      <Form.Label htmlFor={id}>
        {label}
        {required && (
          <sup aria-hidden className="text-danger">
            *
          </sup>
        )}
      </Form.Label>
      <Field name={name}>
        {({ field, meta }: FieldProps) => {
          const isInvalid = !!meta.error && meta.touched;
          return (
            <FormControl
              as="input"
              isInvalid={isInvalid}
              aria-invalid={isInvalid}
              id={id}
              aria-describedby={isInvalid ? errorId : undefined}
              required={required}
              {...field}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name}>
        {(msg) => (
          <FormText id={errorId} className="text-danger">
            {msg}
          </FormText>
        )}
      </ErrorMessage>
    </Form.Group>
  );
};
