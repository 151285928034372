import { faCircle, faCirclePlus, faLightbulb, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useCallback, useRef, useEffect } from 'react';
import { Handle, Node, NodeProps, Position, useReactFlow, useStoreApi } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { completeGraph } from '../../services/graph-completion';

import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import useStore, { RFState } from '../../store';
import './index.css';

export type NodeData = {
  label: string;
};

const selector = (state: RFState) => ({
  nodes: state.nodes,
  addChildNode: state.addChildNode,
  updateNodeLabel: state.updateNodeLabel,
  deleteNode: state.deleteNode,
  setNodesAndEdges: state.setNodesAndEdges,
  setThinking: state.setThinking,
});

function PlusIcon(props: any) {
  return (
    <span className="fa-layers fa-fw handle-button" {...props}>
      <i className="fas fa-circle fa-xs fa-stack-1x" style={{ color: 'white' }} />
      <i className="fas fa-plus-circle fa-xs fa-stack-1x" />
    </span>
  );
}

function BranchNode({ id, data }: NodeProps<NodeData>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addChildNode, updateNodeLabel, deleteNode, setNodesAndEdges, nodes, setThinking } = useStore(
    selector,
    shallow,
  );
  const store = useStoreApi();
  const { fitView } = useReactFlow();

  const getChildNodePosition = () => {
    // Use (Infinity, Infinity) for auto-layout
    return {
      x: Infinity,
      y: Infinity,
    };
  };

  const onHandleClick = useCallback(
    (event: any) => {
      const { nodeInternals } = store.getState();

      if (id) {
        const parentNode = nodeInternals.get(id);
        const childNodePosition = getChildNodePosition();

        if (parentNode && childNodePosition) {
          addChildNode(parentNode, childNodePosition);
        }
      }
    },
    [getChildNodePosition],
  );

  const onDeleteClick = () => {
    deleteNode(id);
  };

  const onThinkClick = useCallback(async () => {
    setThinking(true);

    try {
      const { nodes: newNodes, edges: newEdges } = await completeGraph(id, nodes);
      setNodesAndEdges(newNodes, newEdges);
      setTimeout(fitView);
    } finally {
      setThinking(false);
    }
  }, [nodes, setNodesAndEdges, setThinking]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true });
      }
    }, 1);
  }, []);

  return (
    <>
      <span className="node-label">
        <div className="node-label-wrapper">
          <span className="node-label-backing">{data.label}</span>
          <input
            value={data.label}
            onChange={(evt) => updateNodeLabel(id, evt.target.value)}
            className="node-label-input"
            ref={inputRef}
          />
        </div>
        <div className="delete-node">
          <i className="fas fa-trash fa-xs delete-node-icon" onClick={onDeleteClick} />
        </div>
      </span>
      <Handle type="source" position={Position.Bottom} id="t" className="branchHandle">
        <div className="icon-container">
          <PlusIcon onClick={onHandleClick} />
          <i className="fas fa-lightbulb fa-xs handle-button" onClick={onThinkClick} />
        </div>
      </Handle>
    </>
  );
}

export default BranchNode;
