import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. It is our policy to respect your privacy regarding any information we may
        collect from you across our website.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We may collect personal information that you voluntarily provide to us, such as your name, email address, or
        phone number. We may also collect non-personal information about your use of our website, such as your IP
        address or browser type.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We may use your personal information to contact you, provide you with our products or services, or improve our
        website. We may also use your non-personal information to analyze trends and improve our website.
      </p>
      <h2>Sharing Your Information</h2>
      <p>
        We will not share your personal information with third parties unless we have your permission or are required by
        law to do so. We may share your non-personal information with third-party service providers to help us analyze
        trends and improve our website.
      </p>
      <h2>Cookies</h2>
      <p>
        We may use cookies to enhance your experience on our website. A cookie is a small text file that is stored on
        your computer or mobile device when you visit our website.
      </p>
      <h2>Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites that are not governed by this privacy policy. We are not
        responsible for the content, privacy policies, or practices of these third-party websites.
      </p>
      <h2>Security</h2>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
        However, no transmission of data over the internet can be guaranteed to be completely secure. Therefore, we
        cannot guarantee the security of any information you transmit to us.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to change this privacy policy at any time. If we make any material changes to this privacy
        policy, we will notify you by posting the revised policy on our website.
      </p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us.</p>
    </div>
  );
};

export default PrivacyPolicy;
