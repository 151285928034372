export const initialValues = {
  businessName: '',
  productsAndServices: '',
  legalStructure: '',
  ownershipStructure: '',
  companyHistory: '',
  location: '',
  missionAndVision: '',
  targetMarket: '',

  projectedSales: '',
  projectedCosts: '',
  projectedProfit: '',
  capital: '',
  anticipatedCashFlow: '',
  anticipatedReturnOnInvestment: '',
  marketTrendsAndIndustryChange: '',
  projectedFinancialStatements: '',
  keyFinancialMetrics: '',

  strengths: '',
  weaknesses: '',
  opportunities: '',
  threats: '',
  leveragingStrengths: '',
  overcomingWeaknesses: '',

  productTargetCustomer: '',
  demographics: '',
  psychographics: '',
  wantsAndNeeds: '',
  currentlyAddressingNeeds: '',
  marketSize: '',
  keyCompetitors: '',
  customerTrendsAndDrivers: '',
  brandPerception: '',
  pricing: '',

  sizeAndGrowthRate: '',
  majorPlayers: '',
  industryTrendsAndDrivers: '',
  barriersToEntry: '',
  industryTargetCustomer: '',
  competitiveLandscape: '',
  industryStrengthsAndWeaknesses: '',
  threatsAndOpportunities: '',
  regulatoryAndLegalIssues: '',
  futureOfTheIndustry: '',

  tamTargetMarket: '',
  customerPurchasingPower: '',
  customerWillingness: '',
  marketTrendsAndDrivers: '',
  marketGrowth: '',
  marketSubSegments: '',
  geographicInclusionsOrExclusions: '',
  targetMarketSize: '',
  externalFactors: '',

  acceptTerms: false,
};

//populates required fields for easy submit and reset while testing
const testInitialValues = {
  businessName: 'test',
  productsAndServices: 'test',
  legalStructure: 'test',
  ownershipStructure: 'test',
  companyHistory: 'test',
  location: 'test',
  missionAndVision: 'test',
  targetMarket: 'test',

  projectedSales: 'test',
  projectedCosts: 'test',
  projectedProfit: 'test',
  capital: 'test',
  anticipatedCashFlow: 'test',
  anticipatedReturnOnInvestment: 'test',
  marketTrendsAndIndustryChange: 'test',
  projectedFinancialStatements: 'test',
  keyFinancialMetrics: 'test',

  strengths: '',
  weaknesses: '',
  opportunities: '',
  threats: '',
  leveragingStrengths: '',
  overcomingWeaknesses: '',

  productTargetCustomer: '',
  demographics: '',
  psychographics: '',
  wantsAndNeeds: '',
  currentlyAddressingNeeds: '',
  marketSize: '',
  keyCompetitors: '',
  customerTrendsAndDrivers: '',
  brandPerception: '',
  pricing: '',

  sizeAndGrowthRate: '',
  majorPlayers: '',
  industryTrendsAndDrivers: '',
  barriersToEntry: '',
  industryTargetCustomer: '',
  competitiveLandscape: '',
  industryStrengthsAndWeaknesses: '',
  threatsAndOpportunities: '',
  regulatoryAndLegalIssues: '',
  futureOfTheIndustry: '',

  tamTargetMarket: '',
  customerPurchasingPower: '',
  customerWillingness: '',
  marketTrendsAndDrivers: '',
  marketGrowth: '',
  marketSubSegments: '',
  geographicInclusionsOrExclusions: '',
  targetMarketSize: '',
  externalFactors: '',

  acceptTerms: false,
};
