import React, { LegacyRef, createRef, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { Accordion, Container, Spinner, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import { AcceptTerms } from '../../components/accept-terms/AcceptTerms';
import { FormSection } from '../../components/form-section/FormSection';
import { Instructions } from '../../components/instructions/Instructions';
import { ScrollToFieldError } from '../../components/scroll-to-error/ScrollToFieldError';
import { ToastErrorContainer } from '../../components/toast-errors/ToastErrorContainer';

import { getToken } from '../../services/auth/AuthService';
import { initialValues } from './initial-values';
import { QUESTIONNAIRE } from './questionnaire';
import { companyFormSchema } from './validation-schema';
import { ErrorWithMessage } from '../../types/error';
import { generatePlan } from '../../services/plan-generation';

const autofill = async (
  values: any,
  setFieldValue: (field: string, value: string) => void,
  setIsAutofilling: (isAutofilling: boolean) => void,
  setErrorMessage: (errorMessage: ErrorWithMessage) => void,
) => {
  setIsAutofilling(true);

  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/autofillQuestionnaire',
    //'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
      body: JSON.stringify(values),
    },
  )
    .then(async (res) => {
      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }
      const resp = await res.json();
      for (const [id, answer] of Object.entries(resp)) {
        console.log(id, answer);
        setFieldValue(id, answer as string);
      }
      setIsAutofilling(false);
    })
    .catch((error) => {
      console.error('Error:', error.message);
      setErrorMessage({
        error: error.message,
        message: 'Failed to autofill the form. Please try again later. Please contact us if the problem persists.',
      });
      setIsAutofilling(false);
    });
};

const CompanyForm = () => {
  // FIXME: Combine these into a single state object with orthogonal states
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorWithMessage | null>(null);
  const [isAutofilling, setIsAutofilling] = useState(false);

  const handleSubmit = (values: any) => generatePlan(values, setIsSubmitted, setErrorMessage);

  const handleCloseErrorToast = () => setErrorMessage(null);

  return isSubmitted ? (
    <Navigate to="/thanks" />
  ) : (
    <Container className="mt-4">
      <ToastErrorContainer errorMessage={errorMessage} onClose={handleCloseErrorToast} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={companyFormSchema}>
        {({ isSubmitting, resetForm, isValid, submitCount, values, setFieldValue }) => (
          <Form noValidate>
            <ScrollToFieldError />

            <Accordion defaultActiveKey={['0', '1', 'instructions']} alwaysOpen>
              <Instructions eventKey="instructions"></Instructions>
              <div className="mt-2 mb-2 flex-row gap-2 d-flex justify-content-end">
                <Button
                  variant="primary"
                  disabled={isAutofilling}
                  onClick={() => {
                    setIsAutofilling(true);
                    autofill(values, setFieldValue, setIsAutofilling, setErrorMessage);
                  }}
                >
                  {isAutofilling ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    <>Autofill</>
                  )}
                </Button>
              </div>
              {Object.entries(QUESTIONNAIRE).map(([section, questions], idx) => (
                <FormSection label={section} questions={questions} key={section} eventKey={idx.toString()} />
              ))}
            </Accordion>

            <div className="mt-2 mb-2 flex-row gap-2 d-flex align-items-center">
              <AcceptTerms />
            </div>

            <div className="mt-2 mb-2 flex-row gap-2 d-flex align-items-center">
              <Button variant="secondary" onClick={() => resetForm}>
                Reset
              </Button>
              <Button variant="success" type="submit" disabled={isSubmitting || !values.acceptTerms}>
                {isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  <>Submit</>
                )}
              </Button>
              {!isValid && submitCount > 0 && (
                <span className="text-danger mx-2">Please correct the invalid fields</span>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Row>
        <small className="mt-3 d-block mb-3 text-muted">
          Disclaimer: The business plan generator on this website is provided for informational purposes only. The
          content generated by the tool is based solely on the inputs provided by the user, and is not intended to be a
          substitute for professional advice, guidance or consultation. Please note that GPT technology employed in the
          generation of the content has the potential to generate hallucinated values, projections, and other
          information that may not reflect reality. We do not guarantee the accuracy or completeness of the generated
          content, and users are solely responsible for their use of the content. We strongly recommend that users seek
          professional advice before making any decisions based on the information provided in the generated business
          plan. By using the business plan generator, users acknowledge that they have read, understood, and agree to
          this disclaimer.
        </small>
      </Row>
    </Container>
  );
};

export default CompanyForm;
