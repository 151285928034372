import React from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';

export const Instructions = ({ eventKey }: { eventKey: string }) => (
  <Accordion.Item eventKey={eventKey}>
    <Accordion.Header>How to Use PlanGenius</Accordion.Header>
    <Accordion.Body>
      <ListGroup as="ol" numbered>
        <ListGroup.Item as="li">
          Complete the required questions {'('}Or use the Autofill button to complete unanswered questions{')'}
        </ListGroup.Item>
        <ListGroup.Item as="li">Click the Submit button at the bottom of the page when finished</ListGroup.Item>
        <ListGroup.Item as="li">PlanGenius will use your answers to create a personalized business plan</ListGroup.Item>
        <ListGroup.Item as="li">Results will be emailed to you at the email associated with your login</ListGroup.Item>
      </ListGroup>
    </Accordion.Body>
  </Accordion.Item>
);
