import { getToken } from './auth/AuthService';
import { ErrorWithMessage } from '../types/error';

export const generatePlan = async (
  values: any,
  setIsSubmitted: (isSubmitted: boolean) => void,
  setErrorMessage: (errorMessage: ErrorWithMessage) => void,
) => {
  console.log('Submitted Values:', JSON.stringify(values));

  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/generatePlan',
    //'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
      body: JSON.stringify(values),
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }
      setIsSubmitted(true);
      return res.json();
    })
    .catch((error) => {
      console.error('Error:', error.message);
      setErrorMessage({
        error: error.message,
        message: 'Failed to submit the form. Please try again later. Please contact us if the problem persists.',
      });
    });
};
