import { useCallback } from 'react';
import { BaseEdge, EdgeProps, Node, Position, getBezierPath, useStore, internalsSymbol } from 'reactflow';

type EdgeParams = {
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  targetPosition: Position;
};

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source: Node, target: Node): EdgeParams {
  const [sourceX, sourceY, sourcePos] = getParams(source, target);
  const [targetX, targetY, _targetPos] = getParams(target, source);

  let sourcePosition = Position.Left;
  let targetPosition = Position.Right;

  if (sourceX < targetX) {
    sourcePosition = Position.Right;
    targetPosition = Position.Left;
  }

  if (source.type == 'root') {
    sourcePosition = sourcePos as Position;
  }

  return {
    sourceX: sourceX as number,
    sourceY: sourceY as number,
    targetX: targetX as number,
    targetY: targetY as number,
    sourcePosition,
    targetPosition,
  };
}

function getParams(nodeA: Node, nodeB: Node) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(node: Node, handlePosition: Position) {
  const handleBounds = node[internalsSymbol]?.handleBounds?.source ?? [];

  const handle =
    handleBounds.length === 1
      ? handleBounds[0]
      : handleBounds.find((h) => h.position === handlePosition) ?? { x: 0, y: 0, width: 0, height: 0 };

  let offsetX = (handle.width || 0) / 2;
  let offsetY = (handle.height || 0) / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  /*switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle?.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }*/

  const x = node.positionAbsolute?.x! + handle.x + offsetX;
  const y = node.positionAbsolute?.y! + handle.y + offsetY;

  return [x, y];
}

function getNodeCenter(node: Node) {
  return {
    x: node.positionAbsolute?.x! + node?.width! / 2,
    y: node.positionAbsolute?.y! + node?.height! / 2,
  };
}

function MindMapEdge(props: EdgeProps) {
  const { source, target /*, sourceX, sourceY, targetX, targetY */ } = props;
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return <BaseEdge path={edgePath} {...props} />;
}

export default MindMapEdge;
