import { Accordion, Button, Container, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { FormikValues } from 'formik';
import { FormikWizard } from 'formik-wizard-form';
import * as Yup from 'yup';

import { ErrorWithMessage } from '../../types/error';
import { FormSection } from '../../components/form-section/FormSection';
import { ToastErrorContainer } from '../../components/toast-errors/ToastErrorContainer';

import './index.css';
import { QUESTIONNAIRE } from './questionnaire';
import { initialValues } from '../company-form/initial-values';
import { Navigate } from 'react-router-dom';
import { generatePlan } from '../../services/plan-generation';
import { AcceptTerms } from '../../components/accept-terms/AcceptTerms';
import GeniusChat, { Message } from '../../components/genius-chat/GeniusChat';

const PlanGeniusWizard = () => {
  // FIXME: Combine these into a single state object with orthogonal states
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorWithMessage | null>(null);

  const [conversation, setConversation] = useState<Message[]>([]);

  const handleSubmit = (values: any) => generatePlan(values, setIsSubmitted, setErrorMessage);

  const handleCloseErrorToast = () => setErrorMessage(null);

  return isSubmitted ? (
    <Navigate to="/thanks" />
  ) : (
    <Container className="mt-3 d-flex flex-column flex-1 h-100 mw-100 overflow-hidden">
      <ToastErrorContainer errorMessage={errorMessage} onClose={handleCloseErrorToast} />
      <FormikWizard
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnNext
        activeStepIndex={0}
        steps={[
          ...Object.values(QUESTIONNAIRE).map((section) => ({
            component: Step,
            validationSchema: Yup.object().shape(
              Object.entries(section).reduce((acc, [questionId, question]) => {
                if (question.required) {
                  acc[questionId] = Yup.string().required('This field is required');
                }
                return acc;
              }, {} as FormikValues),
            ),
          })),
          {
            component: FinalStep,
          },
        ]}
      >
        {({
          renderComponent,
          handlePrev,
          handleNext,
          isNextDisabled,
          isPrevDisabled,
          isLastStep,
          isSubmitting,
          setFieldValue,
          values,
          currentStepIndex,
        }) => (
          <>
            <div className="d-flex flex-column gap-1 h-100 justify-content-between">
              <div className="d-flex flex-column gap-2 h-100">
                {renderComponent()}
                <div className="d-flex flex-row gap-2 justify-content-end">
                  <Button type="button" className="btn-secondary" onClick={handlePrev} disabled={isPrevDisabled}>
                    Previous
                  </Button>
                  <Button
                    type="button"
                    onClick={handleNext}
                    disabled={isNextDisabled || (isLastStep && !values.acceptTerms)}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : isLastStep ? (
                      'Finish'
                    ) : (
                      'Next'
                    )}
                  </Button>
                </div>
              </div>
              {!isLastStep && (
                <GeniusChat
                  conversation={conversation}
                  setConversation={setConversation}
                  setFieldValue={setFieldValue}
                  context={getContext(values, currentStepIndex || 0)}
                />
              )}
            </div>
          </>
        )}
      </FormikWizard>
    </Container>
  );
};

const getContext = (values: FormikValues | undefined, currentStepIndex: number) => {
  values ||= {};

  return Object.keys(Object.values(QUESTIONNAIRE)[currentStepIndex]).reduce(
    (acc, questionId) => {
      acc[questionId] = values![questionId] || '';
      return acc;
    },
    {
      location: values.location || '',
      productsAndServices: values.productsAndServices || '',
    } as any,
  );
};

const Step = ({ currentStepIndex = 0 }) => {
  const [section, questions] = Object.entries(QUESTIONNAIRE)[currentStepIndex];
  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
      <FormSection label={section} questions={questions} key={section} eventKey="0" />
    </Accordion>
  );
};

const FinalStep = () => {
  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0" as="fieldset">
        <Accordion.Header as="legend">Terms & Conditions</Accordion.Header>
        <Accordion.Body>
          <AcceptTerms />
          <Row>
            <small className="mt-3 d-block mb-3 text-muted">
              Disclaimer: The business plan generator on this website is provided for informational purposes only. The
              content generated by the tool is based solely on the inputs provided by the user, and is not intended to
              be a substitute for professional advice, guidance or consultation. Please note that GPT technology
              employed in the generation of the content has the potential to generate hallucinated values, projections,
              and other information that may not reflect reality. We do not guarantee the accuracy or completeness of
              the generated content, and users are solely responsible for their use of the content. We strongly
              recommend that users seek professional advice before making any decisions based on the information
              provided in the generated business plan. By using the business plan generator, users acknowledge that they
              have read, understood, and agree to this disclaimer.
            </small>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default PlanGeniusWizard;
