import { useRef } from 'react';

export type DeferredPromise<T> = {
  resolve: (value: T) => void;
  reject: (reason?: any) => void;
  promise: Promise<T>;
};

export function useDeferredPromise<T = unknown>() {
  const deferRef = useRef<DeferredPromise<T> | null>(null);

  const defer = () => {
    const deferred = {} as DeferredPromise<T>;

    const promise = new Promise<T>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    deferred.promise = promise;
    deferRef.current = deferred;
    return deferRef.current;
  };

  return { defer, deferRef };
}
