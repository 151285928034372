import { Node, XYPosition } from 'reactflow';

export type TreeNode = Node;

interface TreeNodeWithChildren extends TreeNode {
  children?: TreeNodeWithChildren[];
}

interface TreeNodeWithPosition extends TreeNode {
  children?: TreeNodeWithPosition[];
}

export const layout = (tree: Node[], layoutStrategy = centroidLayout): TreeNodeWithPosition[] => {
  const nodesWithChildren = addChildren(tree);
  return layoutStrategy(nodesWithChildren);
};

function addChildren(tree: Node[]): TreeNodeWithChildren[] {
  // Create a lookup table for quick access to nodes by id
  const nodeLookup: { [key: string]: TreeNodeWithChildren } = {};
  const result = tree.map((node) => (nodeLookup[node.id] = { ...node, children: [] }));

  // Add child nodes to their parent's children array
  result.forEach((node) => {
    if (node.parentNode) {
      const parent = nodeLookup[node.parentNode];
      if (parent.children) {
        parent.children.push(node);
      } else {
        parent.children = [node];
      }
    }
  });

  return result;
}

export function radialTreeLayout(tree: TreeNodeWithChildren[]): TreeNodeWithPosition[] {
  // Set the root node and initialize the layout variables
  const rootNode = tree.find((node) => !node.parentNode) as TreeNodeWithPosition;
  if (!rootNode) {
    throw new Error('No root node found in the mind map.');
  }
  rootNode.position.x = 0;
  rootNode.position.y = 0;
  let maxDepth = 0;

  // Recursively calculate the position of each node
  function calculateNodePosition(node: TreeNodeWithPosition, angle: number, angleRange: number, depth: number) {
    const children = node.children || [];
    const numChildren = children.length;
    const anglePerChild = angleRange / (numChildren || 1);
    let centerAdjust = 0;

    // Calculate the position of this node
    const radius = depth * 100;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    node.position.x = x;
    node.position.y = y;

    if (node.parentNode) {
      centerAdjust = (-angleRange + anglePerChild) / 2;
    }

    let childAngle = angle + centerAdjust;

    // Recursively calculate the position of each child node
    children.forEach((child) => {
      calculateNodePosition(child, childAngle, anglePerChild, depth + 1);
      childAngle += anglePerChild;
    });

    // Update the maximum depth of the tree
    if (depth > maxDepth) {
      maxDepth = depth;
    }
  }

  calculateNodePosition(rootNode, 0, 2 * Math.PI, 0);

  return tree;
}

export function centroidLayout(tree: TreeNodeWithChildren[]): TreeNodeWithPosition[] {
  const position = { x: 0, y: 0 };
  const root = tree[0];
  const leftTree = { id: 'left', data: { label: 'left' }, position, children: [] as TreeNodeWithChildren[] };
  const rightTree = { id: 'right', data: { label: 'right' }, position, children: [] as TreeNodeWithChildren[] };

  root.children?.forEach((child) => {
    if (
      child.position.x < 0 ||
      (child.position.x === Infinity && leftTree.children.length < rightTree.children.length)
    ) {
      leftTree.children.push(child);
    } else {
      rightTree.children.push(child);
    }
  });

  const rootNode: Node = {
    ...root,
    position,
    width: root.width || 0,
  };
  const rightNodes = centroidLayoutSubtree(rightTree, position, 200 + rootNode.width!).nodes;
  const leftNodes = centroidLayoutSubtree(leftTree, position, -200).nodes;

  return [rootNode, rightNodes.slice(1), leftNodes.slice(1)].flat();
}

interface Contour {
  top: number | null;
  bottom: number | null;
}

const emptyContour = (): Contour => ({
  top: null,
  bottom: null,
});

function mergeContours(...contours: Contour[]) {
  return contours.reduce((acc, contour) => {
    acc.top = Math.min(...([acc.top, contour.top].filter(Number.isFinite) as number[]));
    acc.bottom = Math.max(...([acc.bottom, contour.bottom].filter(Number.isFinite) as number[]));
    return acc;
  }, emptyContour());
}

interface TreeLayout {
  sum: number;
  nodes: TreeNodeWithPosition[];
  contour: Contour;
}

const emptyLayout = () => ({
  sum: 0,
  nodes: [] as TreeNodeWithPosition[],
  contour: emptyContour(),
});

function mergeLayouts(...layouts: TreeLayout[]) {
  return layouts.reduce((acc, layout) => {
    acc.sum += layout.sum;
    acc.nodes.push(...layout.nodes);
    acc.contour = mergeContours(acc.contour, layout.contour);
    return acc;
  }, emptyLayout());
}

function centroidLayoutSubtree(
  node: TreeNodeWithChildren,
  position: XYPosition,
  offset = 200,
  parentLayout = emptyLayout(),
): TreeLayout {
  const children = node.children || [];

  const layout = children.reduce((acc, child, index) => {
    const n = index;
    const m = Math.floor(-n / 2);
    let y: number;

    y = -acc.sum;

    if (!acc.sum) {
      y = m;
    }

    //console.log('child', child.label, JSON.stringify({ y, sum: acc.sum, n, m }));

    //child.label = `${index} - ${child.label}`;

    // FIXME: Magic number: 50
    const subTreeLayout = centroidLayoutSubtree(child, { x: position.x + offset, y }, 50, acc);

    return mergeLayouts(acc, subTreeLayout);
  }, emptyLayout());

  /*
    console.log(
      JSON.stringify({
        label: node.label,
        au: `${layout.contour.bottom! + position.y} > ${parentLayout.contour.top}`,
        ad: `${layout.contour.top! + position.y} < ${parentLayout.contour.bottom}`,
        y: position.y,
        lc: layout.contour,
        pl: parentLayout.contour,
      }),
    );
    */

  if (
    parentLayout.contour.top != null &&
    layout.contour.bottom != null &&
    position.y < 0 &&
    layout.contour.bottom! + position.y >= parentLayout.contour.top!
  ) {
    position.y -= layout.contour.bottom! - parentLayout.contour.top!;
    //console.log('adjust up', node.data.label, parentLayout.contour.top! - layout.contour.bottom!, position.y);
  }

  if (
    parentLayout.contour.bottom != null &&
    layout.contour.top != null &&
    position.y > 0 &&
    layout.contour.top! + position.y < parentLayout.contour.bottom!
  ) {
    position.y += parentLayout.contour.bottom! - layout.contour.top!;
    //console.log('adjust down', node.label, parentLayout.contour.bottom! - layout.contour.top!, position.y);
  }

  layout.contour.top! += position.y;
  layout.contour.bottom! += position.y;
  layout.sum = position.y;

  // FIXME: Magic number: 50
  position.y *= 50;

  if (node.position.x != Infinity && node.position.y != Infinity) {
    position = node.position;
  }

  const newNode = {
    ...node,
    position,
  };
  layout.nodes.unshift(newNode);

  //console.log('node', newNode.data.label, newNode.position.x, newNode.position.y, layout.contour, position);
  return layout;
}
