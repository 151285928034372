import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

const thinkingPhrases = [
  'ThinkGenius is flexing its AI muscles to create the perfect mind map for you!',
  'Our AI is hard at work adding its own genius ideas to your mind map.',
  "Don't worry, our AI is doing some extra brainstorming to make your mind map even better!",
  'Your mind map is in the works, with a little help from our AI friends!',
  'ThinkGenius is using its AI wizardry to generate the ultimate mind map for you!',
  'Our AI is like a personal brainstorming assistant, adding its own insights to your mind map!',
  'Sit back and relax, our AI is weaving its magic to create your custom mind map.',
  'ThinkGenius is working hard to make sure your mind map is a true masterpiece of AI-generated brilliance!',
  'Our AI is like a genie in a bottle, conjuring up the perfect mind map just for you!',
  "Your mind map is in good hands with ThinkGenius' AI-powered mind mapping wizardry!",
  "ThinkGenius' AI is the secret ingredient to creating the perfect mind map for you!",
  'Our AI is like a creative genie, adding a touch of magic to your mind map.',
  "ThinkGenius' AI is like a mastermind brainstormer, adding its own brilliance to your mind map.",
  "We're using the power of AI to create a mind map that will blow your mind!",
  "ThinkGenius' AI is working hard to generate a mind map that even Einstein would be proud of!",
  'Our AI is like a personal brainstorming coach, helping you create the perfect mind map.',
  "ThinkGenius' AI is like a mind mapping superhero, swooping in to save the day with the ultimate mind map!",
  'Our AI is like a mind mapping guru, adding its own genius ideas to create the perfect mind map for you.',
  "ThinkGenius' AI is like a creative partner, working alongside you to create the perfect mind map.",
  'Our AI is like a brainstorming buddy, helping you create a mind map that will blow your mind!',
];

function ThinkingOverlay({ thinking }: { thinking: boolean }) {
  const [thinkingPhrase, setThinkingPhrase] = useState('');

  useEffect(() => {
    setThinkingPhrase(thinkingPhrases[Math.floor(Math.random() * thinkingPhrases.length)]);
  }, [thinking]);

  return (
    <div style={{ display: thinking ? 'flex' : 'none' }} className="thinking-modal">
      <div className="thinking-modal-content">
        <FontAwesomeIcon icon={faLightbulb} className="thinking fa-10x" />
        <div className="thinking-modal-text">{thinkingPhrase}</div>
      </div>
    </div>
  );
}

export default ThinkingOverlay;
