import { faCircle, faCirclePlus, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import { Handle, Node, NodeProps, Position, useReactFlow, useStoreApi } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { completeGraph } from '../../services/graph-completion';

import useStore, { RFState } from '../../store';

export type NodeData = {
  label: string;
};

const selector = (state: RFState) => ({
  addChildNode: state.addChildNode,
  updateNodeLabel: state.updateNodeLabel,
  setNodesAndEdges: state.setNodesAndEdges,
  nodes: state.nodes,
  setThinking: state.setThinking,
});

function PlusIcon(props: any) {
  return (
    <span className="fa-layers fa-fw handle-button">
      <FontAwesomeIcon icon={faCircle} size="xs" color="white" />
      <FontAwesomeIcon icon={faCirclePlus} size="xs" color="black" />
    </span>
  );
}

function AddChildHandle({
  onClick,
  position,
  id,
}: {
  onClick: (position: Position) => void;
  position: Position;
  id: string;
}) {
  return (
    <Handle type="source" position={position} onClick={() => onClick(position)} id={id} className="rootHandle">
      <PlusIcon className="handle-button" />
    </Handle>
  );
}

function RootNode({ id, data }: NodeProps<NodeData>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addChildNode, updateNodeLabel, setNodesAndEdges, nodes, setThinking } = useStore(selector, shallow);
  const store = useStoreApi();
  const { fitView } = useReactFlow();

  const getChildNodePosition = (position: Position) => {
    let x = Infinity;

    switch (position) {
      case Position.Right:
        x = 1;
        break;
      case Position.Left:
        x = -1;
        break;
    }

    // we are calculating with positionAbsolute here because child nodes are positioned relative to their parent
    return {
      x,
      y: Infinity,
    };
  };

  const onHandleClick = useCallback(
    (position: Position) => {
      const { nodeInternals } = store.getState();

      if (id) {
        const parentNode = nodeInternals.get(id);
        const childNodePosition = getChildNodePosition(position);

        if (parentNode && childNodePosition) {
          addChildNode(parentNode, childNodePosition);
        }
      }
    },
    [getChildNodePosition],
  );

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${data.label.length * 8}px`;
    }
  }, [data.label.length]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true });
      }
    }, 1);
  }, []);

  const onThinkClick = useCallback(async () => {
    setThinking(true);

    try {
      const { nodes: newNodes, edges: newEdges } = await completeGraph(id, nodes);

      setNodesAndEdges(newNodes, newEdges);
      setTimeout(fitView);
    } finally {
      setThinking(false);
    }
  }, [nodes, setNodesAndEdges, setThinking]);

  return (
    <>
      <input
        value={data.label}
        onChange={(evt) => updateNodeLabel(id, evt.target.value)}
        className="input root-input"
        ref={inputRef}
      />

      <span onClick={onThinkClick} className="think-nodes">
        <FontAwesomeIcon icon={faLightbulb} size="xs" />
      </span>

      <AddChildHandle onClick={onHandleClick} position={Position.Top} id="t" />
      <AddChildHandle onClick={onHandleClick} position={Position.Right} id="r" />
      <AddChildHandle onClick={onHandleClick} position={Position.Bottom} id="b" />
      <AddChildHandle onClick={onHandleClick} position={Position.Left} id="l" />
    </>
  );
}

export default RootNode;
