const PlanGenius = {
  title: 'PlanGenius',
  description: `PlanGenius by FourthNexus is a proof-of-concept demonstration of the power of GPT technology in automated
    business plan generation. By leveraging GPT, PlanGenius showcases the potential of generating
    coherent and relevant text in response to user prompts. By leveraging the latest advancements in
    artificial intelligence, PlanGenius is an example of FourthNexus's commitment to utilizing the most
    cutting-edge technology available to provide customers with the best possible experience.`,
};

const SurveyGenius = {
  title: 'SurveyGenius',
  description: `SurveyGenius by FourthNexus is a proof-of-concept demonstration of the power of GPT technology in automated
    survey generation with Google Forms. By leveraging GPT, SurveyGenius showcases the potential of generating
    coherent and relevant text in response to user prompts. By leveraging the latest advancements in
    artificial intelligence, SurveyGenius is an example of FourthNexus's commitment to utilizing the most
    cutting-edge technology available to provide customers with the best possible experience.`,
};

const ThinkGenius = {
  title: 'ThinkGenius',
  description: `ThinkGenius by FourthNexus is a proof-of-concept demonstration of the power of GPT technology in AI-assisted
    mind mapping. By leveraging GPT, PlanGenius showcases the potential of generating
    coherent and relevant text in response to user prompts. By leveraging the latest advancements in
    artificial intelligence, PlanGenius is an example of FourthNexus's commitment to utilizing the most
    cutting-edge technology available to provide customers with the best possible experience.`,
};

const PAGE_INFO_BY_HOST: Record<string, any> = {
  'plan-genius.fourthnexus.dev': PlanGenius,
  'plan-genius-dev.fourthnexus.dev': PlanGenius,
  'survey-genius.fourthnexus.dev': SurveyGenius,
  'survey-genius-dev.fourthnexus.dev': SurveyGenius,
  'think-genius.fourthnexus.dev': ThinkGenius,
  'think-genius-dev.fourthnexus.dev': ThinkGenius,
  default: {
    title: 'Genius Tools',
    description: `Genius Tools by FourthNexus are a proof-of-concept demonstration of the power of GPT technology in automated
    business tools. By leveraging GPT, Genius Tools showcase the potential of generating
    coherent and relevant text in response to user prompts. By leveraging the latest advancements in
    artificial intelligence, Genius Tools are an example of FourthNexus's commitment to utilizing the most
    cutting-edge technology available to provide customers with the best possible experience.`,
  },
};

export function getPageInfo(host: string) {
  if (host in PAGE_INFO_BY_HOST) {
    return PAGE_INFO_BY_HOST[host];
  }

  return PAGE_INFO_BY_HOST['default'];
}
