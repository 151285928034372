import { Card, Row, Col } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import { Survey } from '../../types/survey';

export const SurveyCard = ({ survey, index }: { survey: Survey; index: number }) => {
  const surveyName = survey.name;
  const editUrl = survey.editUrl;
  const responseUrl = survey.responseUrl;

  return (
    <Card className="mt-3">
      <Card.Header>
        {index + 1}. {surveyName}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={2}>Edit URL:</Col>
          <Col md={10}>
            <Link to={editUrl} target="_blank">
              {editUrl}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={2}>Response URL:</Col>
          <Col md={10}>
            <Link to={responseUrl} target="_blank">
              {responseUrl}
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
